import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-solid border-[2px] rounded-lg font-base w-full px-2 md:px-4 py-4 text-base md:text-xl flex flex-col gap-4 my-2 shadow-lg" }
const _hoisted_2 = { class: "flex flex-col md:flex-row justify-start items-center gap-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "h-24 md:h-12 flex justify-center items-center" }
const _hoisted_5 = { class: "flex flex-col md:flex-row gap-2 items-center justify-start" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "w-16 rounded-full",
        src: _ctx.kuchikomi.xIconSrc
      }, null, 8, _hoisted_3),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.kuchikomi.xAccountName), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            class: "cursor-grab text-sm md:text-base text-blue-500 hover:text-blue-700",
            target: "_blank",
            href: _ctx.kuchikomi.xAccountUrl
          }, _toDisplayString(_ctx.kuchikomi.xAccountId), 9, _hoisted_6),
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.kuchikomi.serviceUrl,
            class: "mt-[2px] cursor-grab text-xs md:text-sm text-green-500 hover:text-green-700"
          }, _toDisplayString(_ctx.kuchikomi.serviceName), 9, _hoisted_7)
        ])
      ])
    ]),
    _createElementVNode("div", {
      ref: "textBoxRef",
      class: _normalizeClass([
        'leading-[1.0rem] md:leading-[3rem] text-fmfm-darkgray text-sm md:text-lg',
        _ctx.isExpanded ? 'h-auto' : 'h-[240px] overflow-hidden',
      ])
    }, null, 2),
    _createElementVNode("div", {
      class: "text-xs md:text-sm text-gray-400 cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleText && _ctx.toggleText(...args)))
    }, _toDisplayString(_ctx.isExpanded ? "一部を表示する" : "すべて表示する"), 1)
  ]))
}