<template>
  <div class="w-11/12 flex flex-col">
    <sub-head1
      :mainText="'インストール方法'"
      :subText="'３ステップにまとめました！'"
    />
    <!-- STEP1 -->
    <img-and-text
      :title="'インストール方法'"
      :img="'fmfm_anime4.gif'"
      :numbering="'1'"
      :noBorder="true"
      :texts="[
        {
          value:
            'ふむふむ君はChromeの拡張機能です。パソコンを準備してブラウザ(Google Chrome)を立ち上げてください。スマホには対応しておりません。',
        },
      ]"
    />

    <!-- STEP2 -->
    <img-and-text
      :title="'ふむふむ君をインストール'"
      :img="'fmfm_anime5.gif'"
      :noBorder="true"
      :numbering="'2'"
      :texts="[
        {
          value: 'インストールはこちらから',
          showLink: true,
          link: 'https://chrome.google.com/webstore/detail/%E3%81%B5%E3%82%80%E3%81%B5%E3%82%80%E5%90%9B/lbcclnedcbmomnihgophjinmkikiidkf?hl=ja&authuser=0',
        },
        {
          value: 'Chromeに追加をクリックしてください。',
        },
      ]"
    />

    <!-- STEP3 -->
    <img-and-text
      :title="'さあ始めよう！'"
      :img="'fmfm_anime6.gif'"
      :numbering="'3'"
      :noBorder="true"
      :texts="[
        {
          value:
            '対応マッチングアプリ(Tinder/Pairs/With/東カレデート)を開いて更新するとふむふむ君が使えるようになっています！Googleアカウントでログインして開始ボタンをクリックしましょう。',
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import ImgAndText from "../components/ImgAndText.vue";
export default defineComponent({
  name: "FmfmInstall",
  props: {},
  data: function () {
    return {
      noBorder: true,
    };
  },
  components: {
    SubHead1,
    ImgAndText,
  },
});
</script>
