import {
  Firestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

// 課金ユーザーか？
const isPremiumUser = async (db: Firestore, uid: string) => {
  const q = query(
    collection(db, `/customers/${uid}/subscriptions`),
    where("status", "in", ["trialing", "active"]),
    where("current_period_end", ">", new Date())
  );
  const querySnapshot = await getDocs(q);
  //console.log("query snap shot")
  return !querySnapshot.empty;
};

const isTrialingUser = async (db: Firestore, uid: string) => {
  const q = query(
    collection(db, `/customers/${uid}/subscriptions`),
    where("status", "==", "trialing"),
    where("current_period_end", ">", new Date())
  );
  const querySnapshot = await getDocs(q);
  //console.log("query snap shot")
  return !querySnapshot.empty;
};

const isCancancelAtPeriodEnd = async (db: Firestore, uid: string) => {
  // console.log(uid);
  const q = query(
    collection(db, `/customers/${uid}/subscriptions`),
    where("status", "in", ["trialing", "active"]),
    where("cancel_at_period_end", "==", true)
  );
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};
export { isPremiumUser, isTrialingUser, isCancancelAtPeriodEnd };
