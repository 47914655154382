<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1
      :mainText="'本格的に使うならVIPプラン！'"
      :subText="'今なら7日間の無料キャンペーン中！'"
    />

    <!--VIPプラン-->
    <div
      class="w-11/12 md:w-1/2 border-fmfm-accent-blue border-4 rounded-2xl p-2 md:p-4 m-4 flex flex-col justify-center items-center gap-2 md:gap-4 shadow-xl"
    >
      <div class="flex flex-row justify-center items-center gap-2">
        <img class="h-10 md:h-12" src="../assets/vip.png" />
        <div class="text-2xl md:text-4xl">VIPプラン</div>
      </div>
      <div class="text-xl md:text-3xl">
        ¥ <span class="text-3xl md:text-5xl">847</span>/月
      </div>

      <div class="w-full flex flex-row justify-start items-center gap-2">
        <img class="h-8 md:h-12" src="../assets/check.png" />
        <div class="relative font-base text-xl md:text-3xl">
          足あと無制限
          <span
            class="absolute left-0 -bottom-5 md:-bottom-7 text-[10px] md:text-[12px] text-fmfm-darkgray"
            >&スワイプ</span
          >
        </div>
      </div>
      <div class="w-full flex flex-row justify-start items-center gap-2">
        <img class="h-8 md:h-12" src="../assets/check.png" />
        <div class="font-base text-xl md:text-3xl">全ての設定機能が使える</div>
      </div>
    </div>
    <text-area
      :texts="[
        {
          value:
            'ふむふむ君を使って本気でマッチングアプリを攻略したい方にはVIPプランを用意しています！VIPプランでは無制限に足あとをつけることができるので、手動ではとても達成できない数の足あと営業をかけることができます。値段はコスパ重視の月額847円。例えば彼女ができるまでの2ヶ月間課金したとしても1694円の出費で済みます。',
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
export default defineComponent({
  name: "FmfmCancel",
  props: {},
  components: {
    SubHead1,
  },
});
</script>
