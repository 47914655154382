<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1
      :mainText="'こんな方におすすめします！'"
      :subText="'足あと営業のイメージはつかめましたか？'"
    />
    <div class="w-full md:w-5/6">
      <sub-head2
        :mainText="'女性と出会うところまで行かない方'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
      <sub-head2
        :mainText="'女性にモテて人生充実させたい方'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
      <sub-head2
        :mainText="'いいねを闇雲に消費してしまっている方'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
      <sub-head2
        :mainText="'人気会員になって美女を狙いたい方'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
      <sub-head2
        :mainText="'忙しくてアプリに時間を割けない方'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
      <sub-head2
        :mainText="'複数アプリを使いこなすガチ勢'"
        :numbering="'✓'"
        :numberingStyle="'bg-black'"
        :textFont="'base'"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import SubHead2 from "../components/SubHead2.vue";
export default defineComponent({
  name: "FmfmPersona",
  props: {},
  components: {
    SubHead1,
    SubHead2,
  },
});
</script>
