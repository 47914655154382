<template>
  <!--足あと営業-->
  <div class="w-11/12 flex flex-col mb-6 md:mb-12">
    <sub-head1 :mainText="title" :subText="subTitle" />
    <div class="video_container">
      <iframe
        width="560"
        :src="url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
export default defineComponent({
  name: "FmfmReviewYoutube",
  props: {
    title: {
      type: String,
      require: true,
      default: `利用者のリアルな声`,
    },
    subTitle: {
      type: String,
      require: true,
      default: `withで初日618いいね達成！`,
    },
    url: {
      type: String,
      require: true,
      default: `https://www.youtube.com/embed/IuqxfaEs8Mo?si=kQmNwmNUoboJ66ZW?controls=0&autoplay=1&mute=1&playsinline=1&loop=1`,
    },
  },
  components: { SubHead1 },
});
</script>

<style scoped>
.video_container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0px;
  overflow: hidden;
}
.video_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
