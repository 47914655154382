import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import "./index.css";
import { FontAwesome } from "./plugins/font-awesome";

document.title = "ふむふむ君";
createApp(App)
  .use(router)
  .use(store, key)
  .component("fa", FontAwesome)
  .mount("#app");
