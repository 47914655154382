<template>
  <section-row>
    <div
      class="w-full mb-24 bg-white p-2 flex justify-end items-start text-base md:text-lg text-blue-300"
    >
      <a
        class="underline"
        href="https://fmfmkun.com/privacypolicy"
        target="_blank"
        >特定商取引法に基づく表記</a
      >
    </div></section-row
  >
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "../store";
import { useRouter } from "vue-router";
import SectionRow from "../components/SectionRow.vue";

export default defineComponent({
  // ログイン画面
  name: "BottomView",
  components: {
    SectionRow,
  },
});
</script>
