interface StripeConfig {
  secretKey?: string;
  publicKey?: string;
}

const stripeConfig = {
  secretKey: process.env.VUE_APP_STRIPE_LIVE_SKEY,
  publicKey: process.env.VUE_APP_STRIPE_LIVE_PKEY,
} as StripeConfig;

export { stripeConfig };
