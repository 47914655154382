import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-wrap justify-center" }
const _hoisted_2 = { class: "w-[98%] p-[1%]" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full grid grid-cols-2 gap-2 md:gap-4 p-2 md:p-4" }
const _hoisted_5 = { class: "w-full grid grid-cols-2 gap-2 md:gap-4 p-2 md:p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_img_and_text = _resolveComponent("img-and-text")!
  const _component_sub_head2 = _resolveComponent("sub-head2")!
  const _component_text_area = _resolveComponent("text-area")!
  const _component_app_kuchikomi = _resolveComponent("app-kuchikomi")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "w-full rounded-lg",
        src: require(`@/assets/cancel/cancel.png`)
      }, null, 8, _hoisted_3)
    ]),
    _createVNode(_component_img_and_text, {
      title: '継続的な足あと営業',
      img: 'cancel/fmfm-sales.gif',
      numbering: '1',
      numberingStyle: 'bg-[#04C9FF]',
      noBorder: true,
      texts: [
        {
          value:
            '足あと営業は継続が何よりも大切です。効果が出るまで毎日1000人を目安に足あとをつけましょう。',
        },
        {
          value:
            '時間帯はスマホを眺めている可能性の高い夜9〜10時が狙い目です。',
        },
      ]
    }, null, 8, ["img"]),
    _createVNode(_component_sub_head2, {
      mainText: 'プロフィール写真の改善',
      numbering: '2',
      numberingStyle: 'bg-[#04C9FF]'
    }),
    _createVNode(_component_text_area, { texts: [
        {
          value:
            '女性から見てプロフィール写真のレベルが低い状態では、どんなに足あと営業をかけたところで大きな効果は得られません。',
        },
        {
          value:
            'ふむふむ君ではマッチングアプリ専門のカメラマンに完璧なプロフィール写真を撮ってもらうことをお勧めしています。',
        },
        {
          value:
            'ふむふむ君とパートナーシップを結んでいる信頼できるマッチングアプリカメラマンを紹介いたします。',
          //emphasis: true,
        },
        {
          value:
            '「完璧なプロフィール写真」×「足あと営業」で大量マッチを目指しましょう！',
        },
      ] }),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photographer, (kuchikomi, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(_component_app_kuchikomi, { kuchikomi: kuchikomi }, null, 8, ["kuchikomi"])
        ]))
      }), 128))
    ]),
    _createVNode(_component_sub_head2, {
      mainText: 'マッチ後のやりとり',
      numbering: '3',
      numberingStyle: 'bg-[#04C9FF]'
    }),
    _createVNode(_component_text_area, { texts: [
        {
          value:
            'いざマッチングしたはいいものの、そのあとが上手く行かないというお悩みありませんか？',
        },
        {
          value:
            'デートへの誘い方が分からない。どんな会話をすればよいか分からない。服装に自信がない...人それぞれ様々なお悩みがあるかと思います。',
        },
        {
          value:
            'ふむふむ君とパートナーシップを結んでいる”信頼できる”発信者の方々を紹介いたします。',
        },
      ] }, null, 8, ["texts"]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.consultant, (kuchikomi, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(_component_app_kuchikomi, { kuchikomi: kuchikomi }, null, 8, ["kuchikomi"])
        ]))
      }), 128))
    ])
  ]))
}