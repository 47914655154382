const consultant = [
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1482016255825498113/LLdaNFG9_400x400.jpg",
    xAccountName: "井上｜マッチングアプリ全国1位",
    xAccountId: "@inoue_camera",
    xAccountUrl: "https://twitter.com/inoue_camera",
    text: "【平均マッチ数5倍超え！】あなたのマッチ率を5倍に引き上げ勝たせるフリーランスのマッチングアプリ専門カメラマン&恋愛講師(28)です📸with登録45日で9,999+マッチ👍(全国1位🥇) &pairs登録15時間で500+いいね👍&タップル登録3時間で99+いいかも獲得。元々は若ハゲ・老け顔・デブの三重苦非モテ→マッチングアプリを経て3ヶ月で約50人とデート。今では毎週のように彼女と旅行してます✨ 写真撮影・講習を通じてあなたの人生を激変させるサポートをします🔥マッチ数を増やしたい方のみご依頼ください！",
    serviceName: "→撮影依頼&コンサル",
    serviceUrl: "https://lin.ee/NgTSm4m",
  },
];

export { consultant };
