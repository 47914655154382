const photographer = [
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1646350988805279744/tqfeIgAa_400x400.jpg",
    xAccountName: "りょう|恋愛コーチ×アプリカメラマン",
    xAccountId: "@RyoTa00photo",
    xAccountUrl: "https://twitter.com/RyoTa00photo",
    text: "【平均マッチ数5倍超え！】あなたのマッチ率を5倍に引き上げ勝たせるフリーランスのマッチングアプリ専門カメラマン&恋愛講師(28)です📸with登録45日で9,999+マッチ👍(全国1位🥇) &pairs登録15時間で500+いいね👍&タップル登録3時間で99+いいかも獲得。元々は若ハゲ・老け顔・デブの三重苦非モテ→マッチングアプリを経て3ヶ月で約50人とデート。今では毎週のように彼女と旅行してます✨ 写真撮影・講習を通じてあなたの人生を激変させるサポートをします🔥\nマッチ数を増やしたい方のみご依頼ください！",
    serviceName: "→撮影依頼",
    serviceUrl: "https://www.timeticket.jp/items/134553/",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1766320290265784320/I0QUFwwc_400x400.jpg",
    xAccountName: "Ryu |マッチングアプリカメラマン×低身長モテスタイリング\n",
    xAccountId: "@Ryu_photo713",
    xAccountUrl: "https://twitter.com/Ryu_photo713",
    text: "業界No.1カメラマンの技術継承！圧倒的にマッチする写真&低身長顔出し無しでも3000人とマッチした運用術を伝授！",
    serviceName: "→撮影依頼",
    serviceUrl: "https://note.com/ryu_photo/n/ne8290bd60920",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1778396711125479424/VSIQcBV8_400x400.jpg",
    xAccountName: "Mくん@マッチングアプリ写真撮影📸〈最安1000円撮影〜",
    xAccountId: "@snsphoto6",
    xAccountUrl: "https://twitter.com/snsphoto6",
    text: '【業界TOP】マッチングアプリ撮影人数1400名以上⤴︎｜非モテ出身！"モテ"を創るカメラマン｜アプリ攻略法やモテる為に必要な有益情報を発信｜インスタ万垢｜各アプリTOPランカー｜ 恋愛コンサル｜ インスタ撮影&運用｜Twitter開始記念！一眼価格の1/3以下でプロの撮影体験やってます！',
    serviceName: "→撮影依頼",
    serviceUrl: "https://twitter.com/snsphoto6",
  },
];

export { photographer };
