import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "fixed bottom-0 w-4/5 md:w-[40%] py-4 md:py-6 mb-4 md:mb-6 text-center text-white md:text-4xl text-xl rounded-2xl shadow-xl hover:scale-105 bg-green-600",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick()))
    }, _toDisplayString(_ctx.title), 1)
  ]))
}