import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
//import VipView from "../views/VipView.vue";
//import LpView from "../views/LpView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import VipView from "@/views/VipView.vue";
import LpView from "@/views/LpView.vue";
import CancelView from "@/views/CancelView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/vip",
    name: "vip",
    component: VipView,
  },
  {
    path: "/",
    name: "home",
    component: LpView,
  },
  {
    path: "/lp",
    name: "lp",
    component: LpView,
  },
  {
    path: "/cancel",
    name: "cancel",
    component: CancelView,
  },
  {
    path: "/hint",
    name: "hint",
    component: CancelView,
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicyView,
  },
  {
    path: "/success",
    name: "success",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SuccessView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
