
import { defineComponent } from "vue";

export default defineComponent({
  name: "InstallButton",
  methods: {
    openLink() {
      window.open(this.url);
    },
    handleClick() {
      // クリックイベントを発火し、任意のデータを伴うこともできます
      this.$emit("onClick", "クリックされました");
    },
  },
  props: {
    title: {
      type: String,
      default: "今すぐ試してみる",
    },
    url: {
      type: String,
      default:
        "https://chrome.google.com/webstore/detail/%E3%81%B5%E3%82%80%E3%81%B5%E3%82%80%E5%90%9B/lbcclnedcbmomnihgophjinmkikiidkf?hl=ja",
    },
  },
});
