<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1 :mainText="'よくある質問'" :subText="'お答えします！'" />
    <sub-head2
      :mainText="'垢バンされませんか？'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-fmfm-accent-blue'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            '心配ありません。ただしPairsでは1日に何度も実行すると足あとをつけすぎという理由で運営から警告をもらう可能性があるようです。',
        },
        {
          value:
            '目安としてはPairs、With、東カレは1日1000人程度、Tinderは1日3000人程度が無難です。',
          emphasis: true,
        },
        {
          value:
            '設定機能で指定した足あと数で止める機能がありますのでご活用ください。',
        },
      ]"
    />

    <sub-head2
      :mainText="'解約方法を教えてください！'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-fmfm-accent-blue'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            'こちらのページでログイン後、「解約手続きを進める」→「プランをキャンセル」すれば解約完了です。',
        },
        {
          value: 'https://fmfmkun.com/cancel',
          link: 'https://fmfmkun.com/cancel',
          showLink: true,
        },
        {
          value:
            '無料トライアル開始後7日以内にキャンセルした場合は一切の費用はかかりません。',
        },
      ]"
    />

    <sub-head2
      :mainText="'スマホ対応はしないのですか？'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-fmfm-accent-blue'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            '足あとをつけるときは長時間動かしますのでパソコンが一番安定します。スマホではバッテリーがへたってしまう可能性があるため対応していません。',
        },
      ]"
    />
    <sub-head2
      :mainText="'追加してほしい機能があります！'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-fmfm-accent-blue'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            'ふむふむ君はユーザーからのリクエストや改善要望に真摯に対応してきました。様々な方のフィードバックやノウハウを取り入れて3年以上更新を続けております。',
        },
      ]"
    />
    <!-- <div class="w-[90%] pl-2 md:pl-4">
      <Tweet tweet-id="1667881485259669504" />
    </div> -->

    <div
      class="w-[90%] flex flex-col gap-3 my-4 items-start pl-2 md:pl-4 font-base text-base md:text-xl text-fmfm-darkgray"
    >
      <div>ご要望はこちらまで</div>
      <div
        class="w-full flex flex-row items-center gap-2 hover:underline"
        @click="openTwitter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
          />
        </svg>
        DM (@fmfm_kun)
      </div>
      <div class="w-full hover:underline">
        <a
          class="!k-text-[#1a202c] flex flex-row items-center gap-2"
          href="mailto:fu360muro@gmail.com"
        >
          <fa
            class="!w-6 md:!w-8 text-gray-900 hover:scale-105"
            icon="envelope"
          />メールはこちらへ
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import SubHead2 from "../components/SubHead2.vue";
import TextArea from "../components/TextArea.vue";
import Tweet from "vue-tweet";
export default defineComponent({
  name: "FmfmQuestion",
  props: {},
  components: {
    //AppIconsView,
    SubHead1,
    SubHead2,
    TextArea,
    //Tweet,
  },
});
</script>
