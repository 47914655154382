
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrialButton",

  methods: {
    handleClick() {
      // クリックイベントを発火し、任意のデータを伴うこともできます
      this.$emit("subscription", "クリックされました");
    },
  },
  props: {
    value: {
      type: String,
      require: true,
      default: "無料トライアルを始める",
    },
    isPremiumUser: {
      type: Boolean,
      require: true,
      default: false,
    },
    isMaintenance: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
});
