import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-2 md:gap-4 p-2 md:p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_head1 = _resolveComponent("sub-head1")!
  const _component_app_kuchikomi = _resolveComponent("app-kuchikomi")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sub_head1, {
      mainText: '多数口コミ頂いております！',
      subText: 'モニター頂いたインフルエンサー様からのコメントを紹介します'
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kuchikomis, (kuchikomi, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createVNode(_component_app_kuchikomi, { kuchikomi: kuchikomi }, null, 8, ["kuchikomi"])
        ]))
      }), 128))
    ])
  ]))
}