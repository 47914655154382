
import {
  PropType,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from "vue";

interface Kuchikomi {
  xIconSrc: string;
  xAccountName: string;
  xAccountUrl: string;
  xAccountId: string;
  text: string;
  serviceName: string;
  serviceUrl: string;
}
export default defineComponent({
  name: "AppKuchikomi",
  props: {
    kuchikomi: {
      type: Object as PropType<Kuchikomi>,
      default: () => ({
        // デフォルトプロパティの設定
      }),
    },
  },

  setup(props) {
    const isExpanded = ref(false);
    const textBoxRef = ref<HTMLElement | null>(null);

    // 省略するテキストの計算と設定
    const truncateText = (text: string) => {
      const textBox = textBoxRef.value;
      if (textBox) {
        textBox.innerHTML = text.replace(/\n/g, "<br>");
        let content = text;
        while (
          textBox.scrollHeight > textBox.clientHeight &&
          content.length > 0
        ) {
          content = content.substring(0, content.length - 1);
          textBox.innerHTML = content.replace(/\n/g, "<br>") + "...";
        }
      }
    };

    // isExpandedが変化したときに呼ばれるwatcher
    watch(isExpanded, (newVal) => {
      if (newVal) {
        nextTick(() => {
          if (textBoxRef.value) {
            textBoxRef.value.innerHTML = props.kuchikomi.text.replace(
              /\n/g,
              "<br>"
            );
          }
        });
      } else {
        nextTick(() => {
          truncateText(props.kuchikomi.text);
        });
      }
    });

    onMounted(() => {
      if (!isExpanded.value) {
        truncateText(props.kuchikomi.text);
      }
    });

    // すべて表示するがクリックされたらisExpandedをtrueにする
    const toggleText = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      toggleText,
      isExpanded,
      textBoxRef,
    };
  },
});
