import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_view = _resolveComponent("header-view")!
  const _component_section_row = _resolveComponent("section-row")!
  const _component_fmfm_cancel_slide = _resolveComponent("fmfm-cancel-slide")!
  const _component_install_button = _resolveComponent("install-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_section_row, { isHeader: true }, {
      default: _withCtx(() => [
        _createVNode(_component_header_view)
      ]),
      _: 1
    }),
    _createVNode(_component_section_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_fmfm_cancel_slide)
      ]),
      _: 1
    }),
    _createVNode(_component_install_button, {
      title: '解約手続きを進める',
      onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.subscription()))
    })
  ], 64))
}