<template>
  <div
    v-if="subText"
    class="w-full text-center pl-2 md:pl-4 pt-8 md:pt-12 text-xs md:text-2xl !leading-none text-fmfm-accent-blue"
    v-html="subText"
  ></div>
  <div
    class="w-full text-center pl-2 md:pl-4 py-2 md:py-4 text-2xl !leading-[1.2] md:text-5xl"
    v-html="mainText"
  ></div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "SubHead1",
  props: {
    mainText: {
      type: String,
      required: true,
    },
    subText: {
      type: String,
      default: "",
      required: true,
    },
  },
});
</script>
