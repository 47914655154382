
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import AppKuchikomi from "../components/AppKuchikomi.vue";
import { kuchikomis } from "@/utils/kuchikomi";

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default defineComponent({
  name: "FmfmKuchikomi",
  data: function () {
    return {
      kuchikomis: shuffleArray(kuchikomis),
    };
  },
  components: {
    SubHead1,
    AppKuchikomi,
  },
});
