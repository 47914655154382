<template>
  <div
    class="w-full text-base md:text-3xl flex flex-col gap-4 justify-center items-center mt-4 md:mt-8 font-base"
  >
    <div class="">マッチングアプリを始めてみたけど</div>
    <div class="">なかなか彼女ができない...</div>
    <div class="">「いいね」をしても反応がない...</div>
    <div class="">美女とマッチングなんて無理に決まっている...</div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "FmfmProblem",
  props: {},
  components: {},
});
</script>
