
import { defineComponent } from "vue";
import { useStore } from "../store";
import { useRouter } from "vue-router";
//import AppIconsView from "./AppIcons.vue";
import FmfmCancelSlide from "@/components/FmfmCancelSlide.vue";
import HeaderView from "../views/HeaderView.vue";
import SectionRow from "../components/SectionRow.vue";
import InstallButton from "@/components/InstallButton.vue";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const moveToPlan = () => {
      router.push("/plan");
    };
    const moveToSuccess = () => {
      router.push("/success");
    };
    // store.dispatch("getProducts");
    return {
      store,
      router,
      moveToPlan,
      moveToSuccess,
    };
  },
  components: {
    HeaderView,
    InstallButton,
    SectionRow,
    FmfmCancelSlide,
    //FmfmPrice,
    //FmfmYoutube,
    //FmfmCancel,
    //FmfmTrialButton,
    //FmfmInstall,

    //FmfmFunction,
  },
  data() {
    return {
      notSignedInWarning: false,
    };
  },
  async mounted() {
    const token = this.$route.query.t;
    if (token) {
      this.store.dispatch("signin", { token: token });
    }
    const page = this.$route.query.page;
    if (page == "success") this.moveToSuccess();

    // chrome拡張からのメッセージを監視する
    window.addEventListener(
      "message",
      (event) => {
        console.log(event);
        if (event?.data?.token) {
          this.store.dispatch("signin", { token: event.data.token });
        }
      },
      false
    );
  },
  computed: {
    isPremiumUser(): boolean {
      return this.store.state.userInfo.isPremiumUser;
    },
    isAuthenticated(): boolean {
      return this.store.state.userInfo.isAuthenticated;
    },
    phraseThree(): string {
      if (this.store.state.userInfo.isTrialingUser) {
        return "あなたは現在トライアル会員です";
      } else if (this.store.state.userInfo.isPremiumUser) {
        return "あなたはVIP会員です";
      } else {
        return "今なら7日間無料！いつでも解約OK！";
      }
    },
    startButtonText() {
      if (this.store.state.userInfo.isPremiumUser) {
        return "支払い情報の確認";
      } else {
        return "無料トライアルを始める";
      }
    },
  },
  methods: {
    signin() {
      this.store.dispatch("signin", { token: undefined });
    },
    subscription() {
      if (!this.store.state.userInfo.isAuthenticated) {
        window.scroll({ top: 0, behavior: "smooth" });
        this.store.dispatch("showWarning");
        setTimeout(() => {
          this.store.dispatch("hideWarning");
        }, 1000);
        return;
      }
      if (this.isPremiumUser) {
        this.store.dispatch("callBillingPortal");
      } else {
        alert("現在契約しているプランはありません");
      }
    },
  },
});
