<template>
  <div class="w-11/12 flex flex-col justify-center items-center gap-4">
    <sub-head1
      :mainText="'料金プラン'"
      :subText="'今なら7日間の無料キャンペーン中！'"
    />
    <div class="w-full flex flex-col justify-center items-center">
      <!--説明BOX-->
      <div class="w-full p-4 flex flex-col justify-center items-center gap-4">
        <!--値段-->
        <div class="flex flex-row justify-center items-end">
          <div class="text-3xl md:text-7xl">¥847</div>
          <div class="text-lg md:text-3xl">/月</div>
          <div class="text-base">（税込）</div>
        </div>
        <div class="py-4">
          <app-icons-view
            :tinder="true"
            :pairs="true"
            :_with="true"
            :tokare="true"
          />
        </div>
        <!--全ての機能をご利用いただけます-->
        <div class="flex flex-row justify-center items-center gap-2">
          <img src="../assets/check.png" class="w-[24px] md:w-[32px]" />
          <div class="text-base md:text-2xl">
            Pairs, With, Tinder, 東カレすべて利用できます！
          </div>
        </div>
        <div class="flex flex-row justify-center items-center gap-2">
          <img src="../assets/check.png" class="w-[24px] md:w-[32px]" />
          <div class="text-base md:text-2xl bg-yellow-100">
            足あとを無制限につけることができます！
          </div>
        </div>

        <div class="flex flex-row justify-center items-center gap-2">
          <img src="../assets/check.png" class="w-[24px] md:w-[32px]" />
          <div class="text-base md:text-2xl">
            便利な機能を使うことができます！
          </div>
        </div>

        <div
          class="mt-[40px] p-4 border-2 border-solid border-yellow-400 w-[80%] relative rounded-xl text-center"
        >
          <div class="flex flex-col justify-center items-center gap-2 text-xl">
            <div class="bg-yellow-200 text-lg md:text-3xl">
              お急ぎください！
            </div>
            <div class="text-base">2025年1月6日から料金を値上げします。</div>
            <div class="text-base">847円→1188円</div>
            <div class="text-base bg-yellow-100">
              2025年1月5日までに課金した場合は値上げ後も847円/月で
            </div>
            <div class="text-base bg-yellow-100">
              お使い頂けますのでお急ぎください
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import AppIconsView from "../views/AppIcons.vue";
import SubHead1 from "../components/SubHead1.vue";
export default defineComponent({
  name: "FmfmPrice",
  props: {},
  components: {
    AppIconsView,
    SubHead1,
  },
});
</script>
