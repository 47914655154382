
import { defineComponent } from "vue";
import SubHead2 from "../components/SubHead2.vue";
import AppKuchikomi from "../components/AppKuchikomi.vue";
import ImgAndText from "../components/ImgAndText.vue";
import TextArea from "../components/TextArea.vue";
import { photographer } from "../utils/photographer";
import { consultant } from "../utils/consultant";

export default defineComponent({
  name: "FmfmCancelSlide",
  data: function () {
    return {
      photographer: photographer,
      consultant: consultant,
    };
  },
  components: {
    //SubHead1,
    TextArea,
    ImgAndText,
    AppKuchikomi,
    SubHead2,
  },
});
