const kuchikomis = [
  {
    // Xのアイコン画像URL
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1646350988805279744/tqfeIgAa_400x400.jpg",
    // Xのアカウント名
    xAccountName: "りょう|恋愛コーチ×アプリカメラマン",
    // XのアカウントID
    xAccountId: "@RyoTa00photo",
    // XのURL
    xAccountUrl: "https://twitter.com/RyoTa00photo",
    // 口コミ
    text: "ふむふむ君を使用させて頂きました！マッチングアプリにおいて足跡営業は超重要です。\nいくら良い写真とプロフを用意しても、待っているだけではいいねは来ません。こちらから足跡を付けて女性にアピールするのです。しかし、手動で足跡を付けるとなるとかなりの時間と労力がかかります。\nそこで、こちらの「ふむふむ君」を使用すると、TVを見てても寝ている間でも自動で足跡を付けてくれます。こんなに効率の良いツールは感動物です✨\nおかげさまで私もバコンバコンいいねが来ます。価格も847円/月と格安で、7日間無料トライアル期間もあり、登録方法もGoogle Chromeから簡単に出来るので迷う心配もありません。\nマッチングアプリを効率的に運用したいのであればやらない理由がないのです。めちゃめちゃオススメなので無料トライアルだけでも試す価値あり！",
    // 掲載したいリンクの表示テキスト
    serviceName: "→撮影依頼",
    // 掲載したいリンクのURL
    serviceUrl: "https://note.com/zundouplanter/n/n2272df1e35e6",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1680533324123758592/FQH-Ac___400x400.jpg",
    xAccountName: "Tomo｜アプリカメラマン×インスタ映え外見コンサル",
    xAccountId: "@ryou_cameraman",
    xAccountUrl: "https://twitter.com/ryou_cameraman",
    text: "以前は手動で行っていた足あと営業ですが、ふむふむ君を使うことでとても楽になりました。撮影にいらしてくれたお客様には必ずオススメしています。\nアカウントBAN対策もしっかりと考慮されていて安心して使うことができます。スワイプ速度の調整や自動メッセージ送信機能も充実しているので、アプリや目的に合わせて利用できるのも非常に使いやすいポイントです。",
    serviceName: "→撮影依頼",
    serviceUrl: "https://note.com/brainy_dietes506/n/ndde18fe4da5c",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1670249591235837952/wN0pfw_Q_400x400.jpg",
    xAccountName: "Taiki@アプリカメラマン3.0×神デートスポット案内人",
    xAccountId: "@taiki__camera\r",
    xAccountUrl: "https://twitter.com/taiki__camera",
    text: "この「ふむふむ君」はマッチングアプリで出会いを生み出すために必須のツールです！！！\nアプリカメラマンである私自身も活用しており、良い出会いをたくさん生み出せました。もはやアプリをやる人・素敵な女の子を勝ち取って付き合いたい人には必須の神ツールだと思っています。\n撮影に来て頂いたクライアント様にもふむふむ君を推奨しており、撮った写真と掛け合わせることでかなりの結果を出してもらっています❤️‍🔥(10倍以上になった！、4000いいねまで伸ばせた、見える世界が変わってきた！等のお声を)\nふむふむ君は多くのアプリに対応していますし、足跡機能はもちろんリスクヘッジのための便利機能等も一番充実しています👏価格も破格なので、マッチングアプリで結果を出したい人は全員取り入れてましょう🙆‍♂",
    serviceName: "→撮影依頼",
    serviceUrl: "https://note.com/st1301/n/n19780957dda5",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1778396711125479424/VSIQcBV8_400x400.jpg",
    xAccountName: "Mくん@マッチングアプリ写真撮影📸〈最安1000円撮影〜",
    xAccountId: "@snsphoto6",
    xAccountUrl: "https://twitter.com/snsphoto6",
    text: "足跡ツールって「垢バンにならないかな？」「設定難しそう」というイメージを持っていたのですが、パソコンでGooglechromeに追加するだけで、すぐ開始することができました。スピードの調節や足跡数の上限も設定できるので安心して使うことができます。例えば1時間手動で足跡をつけるとした時の時給（労働）と比較しても847円/月という料金設定はかなり良心的すぎるのではないかなと思います。今後撮影にいらっしゃるお客様にも紹介していきたいです！",
    serviceName: "→撮影依頼",
    serviceUrl: "https://note.com/sns_photograph/n/n1d5c135e50a1",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1766320290265784320/I0QUFwwc_400x400.jpg",
    xAccountName: "Ryu |マッチングアプリカメラマン×低身長モテスタイリング\n",
    xAccountId: "@Ryu_photo713",
    xAccountUrl: "https://twitter.com/Ryu_photo713",
    text: "とても便利です。特に時間がなかなかない人にとってはうっておきのツール。自分のプロフをひたすら営業してくれるのはありがたいです。自分のお客様にもオススメして実際にご活用いただいた方でいいね数カンストした方もいます。アプリ運用において必須です。",
    serviceName: "→撮影依頼",
    serviceUrl: "https://note.com/ryu_photo/n/ne8290bd60920",
  },
  {
    xIconSrc:
      "https://pbs.twimg.com/profile_images/1482016255825498113/LLdaNFG9_400x400.jpg",
    xAccountName: "井上｜マッチングアプリ全国1位",
    xAccountId: "@inoue_camera",
    xAccountUrl: "https://twitter.com/inoue_camera",
    text: "足跡営業はマッチングアプリ運用においてめちゃくちゃ大事な要素です。時間や労力がかなりかかるので正直煩わしいですが、ふむふむくんを活用すれば足跡営業を自動化出来るのでめちゃくちゃありがたいと感じました。また現代の主要マッチングアプリを網羅しているので様々なアプリでの活用できるのも素晴らしいです。アプリで効率的にマッチ数を増やしたい人はぜひ活用して下さい！",
    serviceName: "→撮影依頼&コンサル",
    serviceUrl: "https://lin.ee/NgTSm4m",
  },
];

export { kuchikomis };
