
import OverlayView from "./views/OverlayView.vue";
import { defineComponent } from "vue";
import { useStore } from "./store";
import BottomView from "./views/BottomView.vue";

export default defineComponent({
  // ログイン画面
  name: "App",

  components: {
    OverlayView,
    BottomView,
  },
  mounted() {
    const store = useStore();
    store.dispatch("addOnAuthStateChanged");
    store.dispatch("getProducts");
  },
});
