<template>
  <div class="flex flex-row gap-1 md:gap-2 items-center">
    <!--Pairs-->
    <div v-if="tinder">
      <img
        class="w-6 md:w-8 rounded-full"
        src="../assets/tinder.png"
        alt="Tinder"
      />
    </div>
    <div v-else>
      <div class="w-6 md:w-8 h-6 md:h-8 rounded-full bg-fmfm-gray"></div>
    </div>

    <!--Pairs-->
    <div v-if="pairs">
      <img
        class="w-6 md:w-8 rounded-full"
        src="../assets/pairs.png"
        alt="Pairs"
      />
    </div>
    <div v-else>
      <div class="w-6 md:w-8 h-6 md:h-8 rounded-full bg-fmfm-gray"></div>
    </div>

    <div v-if="_with">
      <img
        class="w-6 md:w-8 rounded-full"
        src="../assets/with.png"
        alt="With"
      />
    </div>
    <div v-else>
      <div class="w-6 md:w-8 h-6 md:h-8 rounded-full bg-fmfm-gray"></div>
    </div>

    <!--Tokare-->
    <div v-if="tokare">
      <img
        class="w-6 md:w-8 rounded-full"
        src="../assets/tokare.png"
        alt="東カレ"
      />
    </div>
    <div v-else>
      <div class="w-6 md:w-8 h-6 md:h-8 rounded-full bg-fmfm-gray"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  // ログイン画面
  name: "AppIcons",
  components: {},
  props: {
    tinder: {
      type: Boolean,
      default: true,
      required: true,
    },
    tokare: {
      type: Boolean,
      default: true,
      required: true,
    },
    _with: {
      type: Boolean,
      default: true,
      required: true,
    },
    pairs: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  data() {
    return {
      visibleUserMenu: false,
    };
  },
  mounted: () => {
    console.log("");
  },
  computed: {},
  setup() {
    return {};
  },
  methods: {},
});
</script>
