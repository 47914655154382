<template>
  <div class="w-11/12 flex flex-col">
    <sub-head1
      :mainText="'美女とマッチングする戦略'"
      :subText="'ただマッチングを増やすだけじゃない。'"
    />
    <!-- STEP1 -->
    <img-and-text
      :title="'大量の足あとをつける'"
      :img="'fmfm_anime1.gif'"
      :noBorder="true"
      :numbering="'1'"
      :texts="[
        {
          value:
            '足あとはマッチングアプリで女性に自分を知ってもらう一番簡単な方法です。',
        },
        {
          value: 'ふむふむ君は自動で大量の女性に足あとをつけることができます。',
          emphasis: true,
        },
        {
          value:
            '足あとをつけてしばらくすると、一定の割合であなたに足あとを返してくれる女性があらわれます。',
        },
      ]"
    />

    <!-- STEP2 -->
    <img-and-text
      :title="'足あとを返してきた人に「いいね」を送る'"
      :img="'fmfm_anime2.gif'"
      :noBorder="true"
      :numbering="'2'"
      :texts="[
        {
          value: '足あとを返してきた = あなたに興味を持った',
          emphasis: true,
        },
        {
          value:
            'ということ。マッチングする可能性が高いので積極的に「いいね」を送りましょう！この作戦を我々は',
        },
        {
          value: '「足あと営業」',
          emphasis: true,
        },
        {
          value: 'と呼んでいます。',
        },
      ]"
    />

    <!-- STEP3 -->
    <img-and-text
      :title="'「いいね」を集めて人気会員に！'"
      :img="'fmfm_anime3.gif'"
      :noBorder="true"
      :numbering="'3'"
      :texts="[
        {
          value: 'いいね数＝自分の価値。',
          emphasis: true,
        },
        {
          value:
            'こつこつ足あと営業をして「いいね」を集めましょう。同じような人でも、いいね数が多い男性ほど女性からモテます。',
        },
        {
          value:
            '人気会員になればなるほどハイレベルな美女と出会えるようになるのです。',
          emphasis: true,
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
import ImgAndText from "../components/ImgAndText.vue";
export default defineComponent({
  name: "FmfmMarketing",
  props: {},
  data: function () {
    return {
      noBorder: true,
    };
  },
  components: {
    SubHead1,
    ImgAndText,
  },
});
</script>
