<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1
      :mainText="'まずは無料でお試しください！'"
      :subText="'クレカの登録はいっさい不要です！'"
    />
    <!--無料プラン-->
    <div
      class="w-11/12 bg-white md:w-1/2 border-4 rounded-2xl p-2 md:p-4 m-4 flex flex-col justify-center items-center gap-2 md:gap-4 shadow-xl"
    >
      <div class="flex flex-row justify-center items-center gap-2">
        <img class="h-10 md:h-12" src="../assets/free.png" />
        <div class="text-2xl md:text-4xl">無料プラン</div>
      </div>
      <!-- <div class="text-xl md:text-3xl">¥0</div> -->

      <div class="w-full flex flex-row justify-start items-center gap-2">
        <img class="h-8 md:h-12" src="../assets/check.png" />
        <div class="relative font-base text-xl md:text-3xl">
          足あと1日50人まで
          <span
            class="absolute left-0 -bottom-5 md:-bottom-7 text-[10px] md:text-[12px] text-fmfm-darkgray"
            >&スワイプ</span
          >
        </div>
      </div>
      <div class="w-full flex flex-row justify-start items-center gap-2">
        <img class="h-8 md:h-12" src="../assets/uncheck.png" />
        <div class="font-base text-xl md:text-3xl text-gray-300">設定機能</div>
      </div>
    </div>
    <text-area
      :texts="[
        {
          value:
            'クレカの登録はいっさい不要です。まずはお使いのマッチングアプリでふむふむ君をお試し下さい。1日50人までの制限はありますが、ふむふむ君を使って足あと営業をするイメージを確認するには十分なはずです。',
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SubHead1 from "../components/SubHead1.vue";
export default defineComponent({
  name: "FmfmFree",
  props: {},
  components: {
    SubHead1,
  },
});
</script>
